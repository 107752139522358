var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-form",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.disabled
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success px-5",
                      attrs: { type: "button" },
                      on: { click: _vm.submeter }
                    },
                    [_vm._v(_vm._s(_vm.model.id == 0 ? "Criar" : "Alterar"))]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-info px-5",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      _vm.$router.push({
                        name: "cadastros.motivoremoverguia.lista"
                      })
                    }
                  }
                },
                [_vm._v("Voltar")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "row mt-4" },
        [
          _c("f-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{required: true}"
              }
            ],
            attrs: {
              name: "descricao",
              disabled: _vm.disabled,
              "data-vv-as": "Descricao",
              label: "Descrição",
              modelErrors: _vm.errors,
              cols: 12
            },
            model: {
              value: _vm.model.descricao,
              callback: function($$v) {
                _vm.$set(_vm.model, "descricao", $$v)
              },
              expression: "model.descricao"
            }
          }),
          _c("f-switch", {
            attrs: {
              name: "comentObrigatorio",
              disabled: _vm.disabled,
              cols: 12,
              label: _vm.model.comentObrigatorio
                ? "Comentário obrigatório habilitado"
                : "Comentário obrigatório não habilitado"
            },
            model: {
              value: _vm.model.comentObrigatorio,
              callback: function($$v) {
                _vm.$set(_vm.model, "comentObrigatorio", $$v)
              },
              expression: "model.comentObrigatorio"
            }
          }),
          _c("f-switch", {
            attrs: {
              name: "ativo",
              disabled: _vm.disabled,
              cols: 12,
              label: _vm.model.ativo ? "Ativo" : "Inativo"
            },
            model: {
              value: _vm.model.ativo,
              callback: function($$v) {
                _vm.$set(_vm.model, "ativo", $$v)
              },
              expression: "model.ativo"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }