<template>
  <card-form>

    <div class="row mt-4">
      <f-input 
        name="descricao" 
        :disabled="disabled"
        v-model="model.descricao" 
        v-validate="{required: true}" 
        data-vv-as="Descricao"
        label="Descrição"
        :modelErrors="errors"
        :cols="12"
      />
      <f-switch
        name="comentObrigatorio"
        :disabled="disabled"
        v-model="model.comentObrigatorio"
        :cols="12"
        :label="model.comentObrigatorio ? 'Comentário obrigatório habilitado' : 'Comentário obrigatório não habilitado'"
      />
      <f-switch
        name="ativo"
        :disabled="disabled"
        v-model="model.ativo"
        :cols="12"
        :label="model.ativo ? 'Ativo' : 'Inativo'"
      />
      
    </div>

    <template #footer>
      <button v-if="!disabled" class="btn btn-success px-5" @click="submeter" type="button">{{ model.id == 0 ? 'Criar' : 'Alterar' }}</button>
      <button class="btn btn-info px-5" @click="() => {$router.push({name: 'cadastros.motivoremoverguia.lista'})}" type="button">Voltar</button>
    </template>

  </card-form>

</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import { Validator } from 'vee-validate';
import { getMotivoRemoverGuia, criarMotivoRemoverGuia, atualizarMotivoRemoverGuia } from "@/services/motivoremoverguia.service";
import { trataErro, trataInsertSucesso, trataUpdateSucesso } from "../../../../utils/tratar.retornos";
import validateCnpj from '../../../../utils/validator.cnpj'


export default {
  components: {
    CardForm,
  },
  data() {
    return {
      disabled: true,
      model: {
        id: 0,
        ativo: true,
        comentObrigatorio: false,
        descricao: ''
      },
    };
  },
  mounted() {
    this.disabled = !this.$route.meta.editavel;
    if (this.$route.params.id && this.$route.params.id > 0) {
      getMotivoRemoverGuia(this.$route.params.id).then(({ data }) => {
        this.model = data.resultado;
      })
      .catch((e) => 
        trataErro(e, this)
      );
    }
    
    Validator.extend('cnpj', validateCnpj);
  },
  methods: {
    async submeter(event) {
      event.preventDefault()
      var isValid = await this.$validator.validateAll();
      if (isValid) {
        if (this.model.id == 0) {
          criarMotivoRemoverGuia(this.model).then(() => {
            trataInsertSucesso(this, 'cadastros.motivoremoverguia.lista')
          })
          .catch((e) => 
            trataErro(e, this)
          )
        } else {
          atualizarMotivoRemoverGuia(this.model).then(() => {
            trataUpdateSucesso(this, 'cadastros.motivoremoverguia.lista')
          })
          .catch((e) => 
            trataErro(e, this)
          )
        }
      }
    },
  },
};
</script>